<template>
  <div v-if="props.loading" class="loader-wrapper">
    <i class="pi pi-spin pi-spinner text-3xl text-zinc-400" />
  </div>
  <Message
    v-if="notAuthorized"
    data-cy="hardware-systems-unauthorized-message"
    severity="warn"
    icon="pi pi-exclamation-triangle"
    :closable="false"
    >{{ $t('notAuthorized') }}
  </Message>
  <DataTable
    v-if="!props.loading && !notAuthorized"
    data-cy="hardware-systems-table"
    :value="systems"
    :paginator="true"
    :alwaysShowPaginator="false"
    :rows="DEFAULT_HARDWARE_SYSTEMS_PAGE_SIZE"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="[
      'name',
      'product_type_name',
      'nidec_id',
      'custom_id',
      'charge_park_name',
      'owner_organisation_name',
      'status',
    ]"
    dataKey="id"
    class="p-datatable-sm mb-5 mt-2 border-rounded overflow-hidden max-w-max"
    v-model:selection="selectedSystem"
    selectionMode="single"
    :metaKeySelection="false"
    @row-click="onRowSelect"
  >
    <template #header>
      <div class="flex justify-between align-items-center p-4">
        <span class="p-input-icon-left search">
          <i class="pi pi-search" />
          <InputText
            data-cy="hardware-systems-table-search"
            v-model="filters['global'].value"
            :placeholder="
              $t('searchBy', {
                name: `${$t('name')}, ${$t('organisations')}, ${$t('hardwareSystem.nidecIds')}...`,
              })
            "
            class="p-inputtext-sm"
          />
        </span>
        <div v-if="props.useUnnasignedFilter" class="flex align-items-center">
          <label for="unassigned" class="unassigned-label">Unassigned</label>
          <Badge :value="unassignedCount" class="unassigned-badge"></Badge>
        </div>
      </div>
    </template>
    <template #empty>
      <span data-cy="hardware-systems-table-empty" class="w-full block text-center">{{
        $t('hardwareSystem.empty')
      }}</span>
    </template>
    <Column
      class="no-top-border-row"
      field="name"
      :header="$t('name')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 14rem"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <div
          data-cy="hardware-system-name-wrapper"
          :system-id="data.id"
          :owner-id="data.owner_organisation_id"
        >
          <span v-if="data.name" data-cy="hardware-system-name">{{ data.name }}</span>
          <span v-else class="empty">--</span>
          <br />
          <span
            class="font-thin text-sm text-gray-400"
            v-if="data.product_type_name"
            data-cy="hardware-system-type"
            >{{ data.product_type_name }}</span
          >
          <span v-else class="font-thin text-sm text-gray-400 empty">--</span>
        </div>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('name') })"
        />
      </template>
    </Column>

    <Column
      class="no-top-border-row"
      field="nidec_id"
      :header="$t('hardwareSystem.nidecId')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <span v-if="data.nidec_id" data-cy="hardware-system-serial-number">{{
          data.nidec_id
        }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('hardwareSystem.nidecId') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="custom_id"
      :header="$t('hardwareSystem.customId')"
      :sortable="true"
      :filterMatchModeOptions="matchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <span v-if="data.custom_id" data-cy="hardware-system-custom-id">{{ data.custom_id }}</span>
        <span v-else class="empty">--</span>
      </template>
      <template #filter="{ filterModel }">
        <InputText
          type="text"
          v-model="filterModel.value"
          class="p-column-filter"
          :placeholder="$t('searchBy', { name: $t('hardwareSystem.customId') })"
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="charge_park_name"
      :header="$t('chargePark.title')"
      :sortable="true"
      :filterMatchModeOptions="statusMatchModes"
      style="min-width: 12rem"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <span v-if="data.charge_park_name" data-cy="hardware-system-charge-park" :data-cy-system-serial="data.nidec_id">{{
          data.charge_park_name
        }}</span>
        
        <span v-if="!data.charge_park_name" class="empty">--</span>
        <br />

        <span
          v-if="data.owner_organisation_name"
          :data-cy-system-serial="data.nidec_id"
          data-cy="hardware-system-organisation"
          class="organisation font-thin text-sm text-gray-400 truncate ..."
          >{{ data.owner_organisation_name }}</span
        >
        <span v-if="!data.owner_organisation_name" class="empty text-gray-400">--</span>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="statuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter mb-2"
          :showClear="true"
        >
        </Dropdown>
      </template>
    </Column>
    <Column
      class="no-top-border-row"
      field="is_bys_connection_online"
      :header="$t('hardwareSystem.status')"
      :sortable="true"
      :showFilterMatchModes="false"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <div
          class="status-badge"
          :class="{
            offline: !data.is_bys_connection_online,
            online: data.is_bys_connection_online,
          }"
          data-cy="hardware-system-status"
          v-tooltip.left="$t('hardwareSystem.systemStatus.infoMessage')"
        >
          <Tag
            data-cy="hardware-system-online-status"
            class="mr-2 status-tag"
            :severity="data.is_bys_connection_online ? 'success' : 'info'"
            :value="
              data.is_bys_connection_online
                ? $t('hardwareSystem.systemStatus.online')
                : $t('hardwareSystem.systemStatus.offline')
            "
            rounded
          ></Tag>
        </div>
      </template>
      <template #filter="{ filterModel }">
        <Dropdown
          v-model="filterModel.value"
          :options="onlineStatuses"
          :placeholder="$t('select', { name: $t('status') })"
          class="p-column-filter"
          showClear
        />
      </template>
    </Column>
    <Column
      class="no-top-border-row no-break"
      field="actions"
      :header="$t('actions')"
      headerStyle="width: 5rem"
    >
      <template #body="{ data }: { data: HardwareSystem }">
        <div class="flex gap-1">
          <Button
            data-cy="open-hardware-system-action"
            
          :data-cy-system-serial="data.nidec_id"
            :label="$t('actions')"
            @click="toggleActions($event, data)"
            icon="pi pi-chevron-down"
            iconPos="right"
            aria-haspopup="true"
            aria-controls="actions_menu"
            class="p-button-outlined p-button-plain p-button-sm mr-3 actions-menu-button"
          />
          <!--@vue-ignore-->
          <Menu
            data-cy="batch-actions-menu"
            :id="'actions_menu_'+data.id"
            :ref="el => { actionsMenus[data.id] = el }"
            :model="actionMenuItems(data)"
            :popup="true"
            class="mt-1" 
          />
        </div>
      </template>
    </Column>
  </DataTable>
  <HardwareSystemTransferModal />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useHardwareSystemsStore } from '@/stores/admin/hardwareSystems/hardwareSystems.store';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import {
  ADMINISTRATOR_ROLE,
  ASSET_MANAGER_ROLE,
  ASSIGN,
  UNASSIGN,
  DEFAULT_HARDWARE_SYSTEMS_PAGE_SIZE,
  DELEGATE,
} from '@/utils/constants';
import { useRouter } from 'vue-router';
import { HARDWARE_SYSTEMS } from '@/utils/routeNames';
import { useUsersStore } from '@/stores/admin/users/users.store';

import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Message from 'primevue/message';
import InputText from 'primevue/inputtext';
import Badge from 'primevue/badge';
import Tag from 'primevue/tag';

import HardwareSystemTransferModal from './HardwareSystemTransferModal.vue';

import type { HardwareSystem } from '@/models/hardwareSystems.model';
import { useI18n } from 'vue-i18n';
const actionsMenus = ref<Menu[]>([]);
const toggleActions = (event: any, data: any) => {
  if(actionsMenus.value[data.id]) actionsMenus.value[data.id].toggle(event);
};
const props = defineProps<{
  systems: HardwareSystem[];
  useUnnasignedFilter?: boolean;
  loading?: boolean;
  notAuthorized?: boolean;
}>();

const { t } = useI18n();
const hardwareSystemsStore = useHardwareSystemsStore();
const router = useRouter();
const userStore = useUsersStore();

const actionMenuItems = (data: any) => {
  let result = [
    {
      key: "DETAILS",
      label: t('details'),
      command: () => navigateToSystemsDetailPage(data),
    },
  ];

  if (activeRoles) { 
    if (data.charge_park_name) {
      result.push({
        key: "UNASSIGN",
        label: t('hardwareSystem.unassignFromSite'),
        command: () => openHardwareSystemModal(data, UNASSIGN),
      });
    } else {
      result.push({
        
        key: "ASSIGN",
        label: t('hardwareSystem.assignTo'),
        command: () => openHardwareSystemModal(data, ASSIGN),
      });
      result.push({
        
        key: "DELEGATE",
        label: t('hardwareSystem.delegateTo'),
        command: () => openHardwareSystemModal(data, DELEGATE),
      });
    }
  }
  
  console.log(data.charge_park_name, result, data)
  return result;
};

/*ref();
 */
const activeRoles = computed(
  () =>
    userStore.me.roles.map((role) => role.id).includes(ADMINISTRATOR_ROLE) ||
    userStore.me.roles.map((role) => role.id).includes(ASSET_MANAGER_ROLE)
);

const { systemModalIsOpen, systemTransferData, actionType } = storeToRefs(hardwareSystemsStore);
const IS_UNASSIGNED = ref('UNASSIGNED');
const IS_ONLINE = ref('ONLINE');
const selectedSystem = ref();

const filters = ref({
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  product_type_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  nidec_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  custom_id: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  charge_park_name: { value: null, matchMode: IS_UNASSIGNED.value },
  owner_organisation_name: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
  },
  is_bys_connection_online: { value: null, matchMode: IS_ONLINE.value },
});

const matchModes = ref([
  { label: 'Starts With', value: FilterMatchMode.STARTS_WITH },
  { label: 'Ends With', value: FilterMatchMode.ENDS_WITH },
  { label: 'Contains', value: FilterMatchMode.CONTAINS },
]);

const statusMatchModes = ref([{ label: 'Status', value: IS_UNASSIGNED.value }]);

FilterService.register(IS_UNASSIGNED.value, (value, filter): boolean => {
  const convertedValue = value ? 'Assigned' : 'Unassigned';

  if (filter === undefined || filter === null) return true;

  return convertedValue === filter;
});

const statuses = ['Unassigned', 'Assigned'];
const onlineStatuses = ['Online', 'Offline'];

FilterService.register(IS_ONLINE.value, (value, filter): boolean => {
  const convertedValue = value ? 'Online' : 'Offline';

  if (filter === undefined || filter === null) {
    return true;
  }
  return convertedValue === filter;
});

const unassignedCount = computed(
  () => props.systems.filter((system: HardwareSystem) => system.charge_park_name === null).length
);

const openHardwareSystemModal = (data: HardwareSystem, action: string) => {
  systemTransferData.value = {
    id: data.id,
    nidec_id: data.nidec_id,
    name: data.name,
    custom_id: data.custom_id,
    owner_organisation_id: data.owner_organisation_id,
    owner_organisation_name: data.owner_organisation_name,
    charge_park_id: data.charge_park_id,
    charge_park_name: data.charge_park_name,
  };
  systemModalIsOpen.value = true;
  actionType.value = action;
};

const navigateToSystemsDetailPage = (data: HardwareSystem) => {
  router.push(`/${HARDWARE_SYSTEMS}/${data.id}`);
};

const onRowSelect = (event: any) => {
  router.push(`/${HARDWARE_SYSTEMS}/${event.data.id}`);
};
</script>

<style lang="scss" scoped>
.organisation {
  width: 120px;
}

.p-input-icon-left {
  width: 65%;

  .p-inputtext {
    width: 100%;

    &::placeholder {
      color: $text-medium;
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
    }
  }
}

.p-checkbox {
  margin: 16px 12px 12px 16px;

  .p-checkbox-box {
    border-color: $grey-medium-light;
  }
}

.unassigned-label {
  color: $accent;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 16px 12px;
}

.p-checkbox .p-checkbox-box.p-highlight .p-checkbox-icon.pi-check::before {
  top: 8px;
  left: 2px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $accent;
  background: $accent;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $accent;
  background: $accent;
  color: #ffffff;
}

.p-badge {
  &.unassigned-badge {
    background: $accent;
    color: #fff;
    margin: 12px 16px 12px 0;
  }
}
</style>

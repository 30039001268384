<template>
  <loading-widget v-if="loading" class="chart" />
  <div v-else>
    <widget-details v-if="showSummary" :items="summaryItems" />
    <v-chart
      ref="chart"
      v-if="options != null"
      :option="options"
      class="chart"
      @zr:mousemove="onMouseMove"
      autoresize
    />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { DatasetComponent, GridComponent, TooltipComponent } from 'echarts/components';
import { PieChart } from 'echarts/charts';
import VChart from 'vue-echarts';
import type { EChartsOption } from 'echarts';
import type { PieChartWidget, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import { loadWidgetData } from '@/stores/admin/dashboard/dashboard.api';
import {
  buildEchartsOption,
  buildSummary,
  type SummaryItem,
} from '@/stores/admin/dashboard/dashboard.logic';
import LoadingWidget from '@/components/dashboard/widget/LoadingWidget.vue';
import WidgetDetails from '@/components/dashboard/misc/WidgetDetails.vue';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

const props = defineProps<{
  widget: PieChartWidget;
  additionalFilters: WidgetFilter[];
  isInteractive?: boolean;
  contextResolver: WidgetContextResolver;
}>();

use([TooltipComponent, PieChart, CanvasRenderer, DatasetComponent, GridComponent]);

const loading = ref(true);
const options = ref<EChartsOption | null>(null);
const error = ref<Error | null>(null);

const summaryItems = ref<SummaryItem[]>([]);
const showSummary = props.widget.type === 'pie';
const colorGen = new ColorGenerator(props.widget.presentation.colorMap);
const chart = ref<typeof VChart | null>(null);

const onMouseMove = () => {
  if (!props.isInteractive) chart.value?.chart?.getZr().setCursorStyle('default');
};

onMounted(async () => {
  const dataResult = await loadWidgetData(props.widget.query, props.additionalFilters);

  if (!dataResult.ok) {
    error.value = dataResult.error;
    return;
  }

  const optionResult = await buildEchartsOption(
    props.widget,
    colorGen,
    dataResult.data,
    props.contextResolver
  );

  if (!optionResult.ok) error.value = optionResult.error;
  else options.value = optionResult.data;

  summaryItems.value = buildSummary(props.widget, dataResult.data, colorGen);
  loading.value = false;
});
</script>

<style scoped lang="scss">
.chart {
  height: 400px;
}
</style>

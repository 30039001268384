<template>
  <v-chart :option="options" class="chart" autoresize />
</template>
<script setup lang="ts">
import VChart from 'vue-echarts';
import { computed } from 'vue';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { GridComponent, TooltipComponent } from 'echarts/components';
import { LineChart } from 'echarts/charts';
import type { EChartsOption } from 'echarts';
import type { DataResponse } from '@/stores/admin/controlPanel/controlPanel.types';
import { convertValue } from '@/utils/units';
import { formatDateTime, formatDateTimeWithUsersTimezone } from '@/utils/dateTimeFormatVue';
import isArray from 'lodash/isArray';
import { cloneDeep } from 'lodash';

const props = defineProps<{
  originalUnit?: string | null;
  desiredUnit?: string | null;
  startUnixEpoch: number;
  endUnixEpoch: number;
  data: DataResponse;
}>();

use([CanvasRenderer, LineChart, GridComponent, TooltipComponent]);

function zip<T, R>(a: T[], b: R[]): [T, R][] {
  return a.map((k, i) => [k, b[i]]);
}

function truncateIfNumber(value: any): any {
  if (typeof value === 'number' && value % 1 !== 0) return value.toFixed(3);
  return value;
}

function dataResponseToEchartsData(
  data: DataResponse,
  fromUnit?: string | null,
  toUnit?: string | null,
  minimalTimestamp?: Date,
  maximalTimestamp?: Date
): EChartsOption {
  const xAxisFormatter = formatDateTimeWithUsersTimezone;
  const xData = data[0].x.data.map((v) => new Date(v * 1000).toISOString());

  let yData = data[0].y.data;
  let valueFormatter = (val: any) => `${truncateIfNumber(val)} ${fromUnit || ''}`;

  if (fromUnit && toUnit && typeof yData[0] === 'number') {
    const [convertedYData, convertedUnit] = convertValue(yData, fromUnit, toUnit);
    yData = convertedYData;
    valueFormatter = (val: any) => `${truncateIfNumber(val)} ${convertedUnit}`;
  }

  const tooltipFormatter = (params: any) => {
    const param = isArray(params) ? params[0] : params;
    return `${xAxisFormatter(param.value[0])}<br/><span style="font-weight: bold">${valueFormatter(
      param.value[1]
    )}</span>`;
  };

  return {
    xAxis: {
      type: 'time',
      min: minimalTimestamp,
      max: maximalTimestamp,
      axisLabel: {
        hideOverlap: true,
        formatter: function (val: number) {
          return formatDateTime(new Date(val));
        },
      },
    },

    animation: false,
    tooltip: {
      trigger: 'item',
      confine: true,
      axisPointer: {
        animation: false,
        type: 'line',
        axis: 'y',
        triggerTooltip: false,
      },
      formatter: tooltipFormatter,
    },
    yAxis: { type: 'value' },
    grid: { left: 5, top: 20, right: 15, bottom: 5, containLabel: true },
    series: {
      data: zip(xData, yData),
      type: 'line',
    },
  };
}

const options = computed(() =>
  dataResponseToEchartsData(
    cloneDeep(props.data),
    props.originalUnit,
    props.desiredUnit,
    new Date(props.startUnixEpoch * 1000),
    new Date(props.endUnixEpoch * 1000)
  )
);
</script>

<template>
  <p v-if="dateString !== null" :class="{ 'font-semibold': props.bold }">
    {{ dateString }}
  </p>
  <p v-else class="unknown">--</p>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import {
  formatDateTimeWithUsersTimezone,
  timezoneUtcOffsetHours,
  forTimezoneUtcOffsetHours,
  formatDateTimeWithTimezone,
  formatDateWithUsersTimezone,
  formatDateWithTimezone,
} from '@/utils/dateTimeFormatVue';

type Props = {
  date?: Date | string | number | null; // Number = unix epoch in seconds
  showTimezone?: boolean;
  bold?: boolean;
  overrideTimezone?: string;
  onlyDate?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  timestyle: 'medium',
  dateStyle: 'medium',
  showTimezone: true,
});

const dateString = computed(() => {
  if (!props.date) return null;
  let resultString = '';
  if (props.onlyDate) {
    if (props.overrideTimezone) {
      resultString += formatDateWithTimezone(props.date, props.overrideTimezone);
    } else {
      resultString += formatDateWithUsersTimezone(props.date);
    }
  } else {
    if (props.overrideTimezone) {
      resultString += formatDateTimeWithTimezone(props.date, props.overrideTimezone);
    } else {
      resultString += formatDateTimeWithUsersTimezone(props.date);
    }
  }
  if (props.showTimezone) {
    resultString += ' UTC';
    if (props.overrideTimezone) {
      resultString += forTimezoneUtcOffsetHours.value(
        props.date ? props.date : new Date(),
        props.overrideTimezone
      );
    } else {
      resultString += timezoneUtcOffsetHours.value(props.date ? props.date : new Date());
    }
  }
  return resultString;
});
</script>

<style scoped lang="scss">
p {
  font-size: 0.9rem;
  color: var(--gray-600);

  &.unknown {
    color: var(--gray-400);
  }
}
</style>

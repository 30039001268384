<template>
  <div class="info-grid">
    <p data-cy="system-details-nidec-id-title" class="label">{{ $t('hardwareSystem.nidecId') }}</p>
    <p data-cy="system-details-nidec-id-value" class="value" :class="{ none: !props.nidecId }">
      {{ props.nidecId ?? '--' }}
    </p>
    <p data-cy="system-details-version-title" class="label">{{ $t('update.firmwareVersion') }}</p>
    <p
      data-cy="system-details-version-value"
      @click="emits('go-to-firmware')"
      class="value"
      :class="{ none: !props.firmwareVersion }"
    >
      {{ props.firmwareVersion || '--' }}
    </p>
    <p data-cy="system-details-custom-id-title" class="label">
      {{ $t('hardwareSystem.inventoryNo') }}
    </p>
    <p data-cy="system-details-custom-id-value" class="value" :class="{ none: !props.customId }">
      {{ props.customId ?? '--' }}
    </p>
    <p data-cy="system-details-nidec-id-title" class="label">
      {{ $t('hardwareSystem.lastOnline') }}
    </p>
    <date-time-display :date="props.lastOnline" />
  </div>
</template>

<script setup lang="ts">
import DateTimeDisplay from '@/components/common/time/DateTimeDisplay.vue';

const props = defineProps<{
  lastOnline?: Date | string;
  nidecId?: string;
  firmwareVersion?: string;
  customId?: string;
}>();

const emits = defineEmits<{
  (event: 'go-to-firmware'): void;
}>();
</script>

<style scoped lang="scss">
.info-grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin: 1rem;
  grid-column-gap: 1rem;
  grid-row-gap: 0.25rem;
  padding: 0.5rem;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background-color: var(--gray-25);

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, auto);
  }
}

p {
  margin: 0;
  font-size: 0.875rem;

  &.label {
    color: var(--gray-400);
  }

  &.none {
    color: var(--gray-400);
  }
}
</style>

<template>
  <loading-widget v-if="loading" class="chart" />
  <v-chart
    v-else-if="options != null"
    :option="options"
    ref="chart"
    class="chart"
    @zr:mousemove="onMouseMove"
    autoresize
    @click="onClick"
  />
</template>

<script setup lang="ts">
import VChart from 'vue-echarts';
import { CanvasRenderer } from 'echarts/renderers';
import { DatasetComponent, GridComponent, TooltipComponent } from 'echarts/components';
import { use } from 'echarts/core';
import type { BarChartWidget, WidgetFilter } from '@/stores/admin/dashboard/dashboard.types';
import { onMounted, ref } from 'vue';
import type { EChartsOption } from 'echarts';
import { loadWidgetData } from '@/stores/admin/dashboard/dashboard.api';
import { BarChart } from 'echarts/charts';
import { buildEchartsOption } from '@/stores/admin/dashboard/dashboard.logic';
import LoadingWidget from '@/components/dashboard/widget/LoadingWidget.vue';
import type { CallbackDataParams } from 'echarts/types/src/util/types';
import type { WidgetContextResolver } from '@/stores/admin/dashboard/dashboard.logic.context';
import { ColorGenerator } from '@/stores/admin/dashboard/dashboard.logic.colors';

use([TooltipComponent, BarChart, CanvasRenderer, DatasetComponent, GridComponent]);

const props = defineProps<{
  widget: BarChartWidget;
  additionalFilters: WidgetFilter[];
  isInteractive?: boolean;
  contextResolver: WidgetContextResolver;
}>();

const loading = ref(true);
const options = ref<EChartsOption | null>(null);
const error = ref<Error | null>(null);
const colorGen = new ColorGenerator(props.widget.presentation.colorMap);
const chart = ref<typeof VChart | null>(null);

const emit = defineEmits<{
  (e: 'click', payload: { dimensionId: string; value: string }): void;
}>();

const onClick = (params: CallbackDataParams) => {
  if (!props.isInteractive) return;
  const xAxis = params.dimensionNames?.[0];
  if (!xAxis) return;
  emit('click', { dimensionId: xAxis, value: params.name });
};

const onMouseMove = () => {
  if (!props.isInteractive) chart.value?.chart?.getZr().setCursorStyle('default');
};
onMounted(async () => {
  const loadData = await loadWidgetData(props.widget.query, props.additionalFilters);

  if (!loadData.ok) {
    error.value = loadData.error;
    return;
  }

  const buildOption = await buildEchartsOption(
    props.widget,
    colorGen,
    loadData.data,
    props.contextResolver
  );

  if (!buildOption.ok) {
    error.value = buildOption.error;
    return;
  }

  options.value = buildOption.data;
  loading.value = false;
});
</script>

<style scoped lang="scss">
.chart {
  height: 400px;
}
</style>

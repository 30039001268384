import type {
  WidgetData,
  WidgetFilter,
  WidgetQuery,
} from '@/stores/admin/dashboard/dashboard.types';
import { examplePanel } from '@/stores/admin/dashboard/dashboard.data';
import axios from 'axios';
import { mergeFilters } from '@/stores/admin/dashboard/dashboard.logic';
import { Err, type Result } from '@/@types';
import { Ok } from '@/@types';

export async function loadPanel() {
  return examplePanel;
}

export async function loadWidgetData(
  query: WidgetQuery,
  additionalFilters: WidgetFilter[] = []
): Promise<Result<WidgetData>> {
  const queryWithFilters = mergeFilters(query, additionalFilters);

  return await axios
    .post(`/api/data/aggregate/`, queryWithFilters)
    .then((res) => Ok(res.data))
    .catch((e) => Err(e));
}

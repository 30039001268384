<template>
  <div class="w-full overflow-x-auto flex justify-start">
    <div v-for="(group, groupIdx) in props.tabGroups" :key="groupIdx" class="flex">
      <div
        v-for="(tab, tabIdx) in group"
        :key="tabIdx"
        class="tab"
        :data-cy="`tab-${tab.id}`"
        :class="{ active: tab.id === props.activeTab }"
        @click="() => setActiveTab(tab.id)"
      >
        {{ tab.label }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  tabGroups: { id: string; label: string }[][];
  activeTab: string | null;
}>();

const emits = defineEmits<{
  (e: 'update:activeTab', tab: string): void;
}>();

const setActiveTab = (tab: string) => {
  emits('update:activeTab', tab);
};
</script>

<style lang="scss" scoped>
.tab {
  cursor: pointer;
  padding: 0.5rem 1rem;
  white-space: nowrap;
  color: var(--gray-500);
  font-size: 0.925rem;
  font-weight: 500;
  border-bottom: 2px solid transparent;
  transition: border-color 0.2s;

  &:hover:not(.active) {
    border-color: var(--gray-200);
  }

  &.active {
    color: var(--green-primary);
    border-color: var(--green-primary);
  }
}
</style>
